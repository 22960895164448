<template>
    <div :class="{ containerMinus: windowWidth > 819, container: windowWidth < 819 }">
        <div class="top">
            <div class="logo">
                <router-link to="/"><img alt="KimberlyHornberg.com" src="./assets/logo-kh.png"
                        class="logo" /></router-link>
            </div>
            <div class="titleNav">
                <div>
                    <h1 class="mainTitle">Kimberly Hornberg</h1>
                </div>
                <NavbarView />
            </div>
        </div>
        <router-view />
        <FooterView />
    </div>
</template>

<script>
import NavbarView from "./components/NavbarView.vue"
import FooterView from "./components/FooterView.vue"

export default {
    components: { NavbarView, FooterView },
    data() {
        return {
            windowWidth: window.innerWidth,
        }
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },

}
</script>


<style>
body {
    background: #efebdf;
}

#app {
    background: #efebdf;
    font-family: "Nunito Sans", sans-serif, Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-optical-sizing: auto;
    font-weight: 350;
    font-style: normal;
    font-size: 14px;

    /*text-align: center;*/
    color: #2c3e50;
}

.container {
    font-family: "Nunito Sans", sans-serif, Helvetica, Arial;
}

.containerMinus {
    padding-left: 10%;
    padding-right: 10%;
}

h1 {
    font-family: "Alex Brush", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 550;
    font-style: normal;
    font-size: 36px;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

h2 {
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

h3 {
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 150;
    font-style: normal;
}

h3 {
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
}

.top {
    display: flex;
    /*flex;*/
    justify-content: space-evenly;
    /* space-between;*/
    align-items: center;
}

.titleNav {
    width: 80%;
}

.mainTitle {
    text-align: center;
}

button {
    margin: 0 10px;
    padding: 10px;
    border: none;
    border-radius: 4px;
}


div .logo {
    text-align: left;
    width: 100px;
    margin-top: -20px;
}

img .logo {
    /* text-alsign: left; */
}
</style>
