<template>
  <div class="blog">
    <h1>Blog</h1>
    <!-- directives:[] -->
    <div id="content"><h2 id="my-journey-to-a-one-hour-faster-half-marathon-in-8-months">My Journey to a One-Hour Faster Half Marathon in 8 Months</h2>
        <p><em>11-11-2024</em></p>
<p>Eight months ago, I could barely run five minutes. Now, I’ve shaved nearly an hour off my half marathon time! In March 2024, I completed my first half marathon at 3 hours and 11 minutes. It was a massive milestone, especially given the 2200 feet of climbing on that route. But last weekend, I hit a new half-marathon personal best of 2 hours and 16 minutes during a 25k (15-mile) race that I completed in 2:38!</p>
<p>So, how did I do it?</p>
<p>It wasn’t just a single change but a combination of new habits and strategies that made the difference. Here’s how I went from a total beginner to a stronger runner (albeit still relatively slow but better!) in less than a year:</p>
<p><strong>1. Consistent Training</strong><br />
I structured my training around three runs a week: one speed/track workout, one hill or trail session, and one long run. Strength training twice a week has also been a game-changer, boosting my stamina and helping prevent injuries.</p>
<p>And here’s my golden rule: no two consecutive days off. Taking a break is fine, but if I rest two days in a row, it’s harder to regain momentum. Even if I’m tired, I’ll at least do some yoga or take a walk—anything to stay in the groove and prevent that dreaded brain and body rot.</p>
<p><strong>2. Dialed-In Nutrition</strong><br />
Nutrition has been another major area of improvement. For tracking, I enjoy Cronometer which is a free and easy-to-use app that helps me balance calories, macros, and micronutrients. I aim for protein to make up 25-30% of my daily intake, splitting the rest between carbs and fats. I’m not obsessive about it, but it gives me useful guidelines. I understand that tracking is not something that is suitable for everyone, but I find that it removes the guesswork of my nutrition and I find that it gives me a better sense of control.</p>
<p>I also made a few dietary adjustments:</p>
<ul>
<li>I know that I have a gluten sensitivity so I try to avoid it as much as possible knowing that it’ll result in discomfort and inflammation. I’m also mindful of my sugar intake to prevent inflammation.</li>
<li>Reduced alcohol—now it’s a once-a-week indulgence instead of a daily habit.</li>
<li>Fuel before training—I avoid fasted workouts, as they make me feel sluggish. I’ve tried training fasted and fueled and have found that my fasted workouts drastically compromise my effort levels.</li>
<li>I prioritize staying within a caloric window 5-6 days a week as a guideline for maintaining energy levels, fat loss, and muscle development</li>
</ul>
<p>If you’re interested, I’d love to dive deeper into my training nutrition in a future post.</p>
<p><strong>3. Finding a Community</strong><br />
Training with others has changed everything. I joined two local running groups (one for track workouts, another for trail runs), and it’s made such a difference. Not only do I get that extra push on tough days, but I’m surrounded by people who inspire me to keep going. Honestly, the encouragement and sense of belonging make a huge impact.</p>
<p><strong>4. Adjusting My Meds</strong><br />
After cancer, managing my thyroid levels is a continual process. In May, I discovered that the meds I was on weren’t working, so we adjusted, and I felt a major shift. The difference in my energy, recovery, and overall well-being was huge. When my thyroid levels are stable, everything else falls into place a bit more easily.</p>
<p><strong>5. Prioritizing Sleep</strong><br />
I know—sleep is the one thing everyone talks about but few prioritize. But if I’ve learned one thing, it’s that all the training in the world means nothing without rest. I’ve stopped feeling guilty about needing naps or calling it an early night. Proper sleep is like hitting the “refresh” button for my body and mind.</p>
<p>These adjustments weren’t about finding extra hours in the day. They were about choosing smarter alternatives—replacing screen time, for example, with training or winding down earlier to get better sleep. Each day’s effort builds toward something bigger, and it’s incredible how a little progress each day can add up.</p>
<h3 id="looking-ahead">Looking Ahead</h3>
<p>I’m working on a video series to go deeper into each of these points because I’ve had so many questions about them. So if you’re on a similar journey, just remember: it’s not about huge leaps. Just focus on today. Can you be a little faster, better, and stronger than you were yesterday? Challenge your self-limiting beliefs, start before you’re ready, and tackle what you can today.</p>
<p>If you’re interested in more strength training and running blogs, let me know! I have some big goals next year and I’d love to help you with yours. 🌟</p>
<p>In health &amp; happiness,</p>
<p><em>Kim</em></p></div>
  </div>
</template>
