<template>
  <div class="about">
<!-- directives:[] -->
<div id="content"><h1 id="about">About</h1>
<p>Kimberly Hornberg is an integrative nutritionist dedicated to helping people achieve their best health through a holistic, whole-body approach. With a Master of Science in Nutrition and Integrative Health, specializing in Human Clinical Nutrition, from Maryland University of Integrative Health (soon to be the School of Integrative Health at Notre Dame of Maryland University in 2024-2025), she combines her expertise with a deep passion for functional health. Currently a CNS (Certified Nutrition Specialist) candidate, Kim is committed to expanding her credentials to better serve her clients.</p>
<p>Rather than just treating symptoms, Kim focuses on uncovering the root causes of health issues, using an integrative approach to create personalized, strategic wellness plans. She believes that every system in the body is interconnected and that addressing these connections can lead to long-term health improvements.</p>
<h1 id="background">Background</h1>
<p>Growing up in an equestrian family, Kim competed in Three-Day Eventing alongside her mother, a passion that shaped her drive and commitment. She’s also an accomplished painter with work exhibited in Northern California and abroad. Kim’s creativity shines in all aspects of her life, including her professional career.</p>
<p>With a Bachelor of Science in Business, Entrepreneurship, and Small Business Management, Kim followed in the entrepreneurial footsteps of her parents. Before turning 30, she was diagnosed with thyroid cancer, after years of unexplained health challenges. Her personal experience with being dismissed by conventional healthcare inspired her to take control of her own health and seek a different path—one focused on finding the root causes of illness, rather than just managing symptoms.</p>
<p>This journey led her to pursue advanced education in integrative health, where she found a community of like-minded peers and mentors. Kim learned that many chronic conditions are preventable, and it became her mission to empower others with the knowledge and tools to take charge of their own health. Through her practice, she helps clients gain confidence in managing their health and building sustainable, healthy lifestyles.</p></div>  </div>
</template>
