<template>
  <div class="services">
    
    <h1 id="services">Services</h1>
<h2 id="affordable-integrative-nutrition-services">Affordable Integrative Nutrition Services</h2>
<p>As a post-graduate in nutrition working under the guidance of a BCNS supervisor, Kim offers integrative nutrition services at affordable rates to make personalized health support accessible to a broad range of clients. With the oversight of a certified professional, you can feel confident in the care and expertise behind each session. Her programs are flexible, without any long-term contracts, so you can receive support in a way that best fits your needs, free from any obligations. Kim's approach is designed to meet you where you are and help you achieve your health goals on your own terms. Her integrative approach to nutrition covers a wide range of personalized services tailored to your unique needs and health goals.&nbsp;</p>
<h3><b>During sessions, you may explore topics like these</b></h3>
<ul>
<li><b>Nutrition &amp; Meal Planning:</b> Learn how to create balanced, nutrient-dense meals that align with your caloric needs and macronutrient targets.</li>
<li><b>Physical Fitness Guidance:</b> From beginners to more experienced athletes, Kimberly offers guidance on incorporating fitness into your lifestyle.</li>
<li><b>Meal Structuring:</b> Discover how to structure meals that support your individual health goals, whether for weight management, muscle building, or overall wellness.</li>
<li><b>Supplementation:</b> Gain insight into supplements that may complement your diet and enhance your overall health.</li>
<li><b>Mental Health &amp; Self-Care:</b> Discuss strategies for managing stress, building resilience, and cultivating a healthy mindset.</li>
<li><b>Hydration &amp; Sleep:</b> Understand the importance of proper hydration and sleep for achieving optimal health and well-being.</li>
<li><b>Drug-Nutrient Interactions:</b> Learn how medications may impact your nutrition and how to manage any potential interactions.</li>
<li><b>Health Education &amp; Self-Advocacy:</b> Kimberly empowers you to take charge of your health by providing the education and tools you need to advocate for yourself within the healthcare system.</li>
</ul>
<p>Each session is designed to support you in building sustainable habits that enhance not only your physical health but also your mental and emotional well-being. Kimberly’s goal is to provide comprehensive, holistic care that nurtures all aspects of your health journey. After each session, you'll receive a personalized summary of what we discussed, along with clear, actionable goals to help keep you on track.</p>
<a href="https://my.practicebetter.io/#/6712de165e45cccaa05a95ec/bookings?step=services"><Button class="bookNow">Book Now!</Button></a>
<h3><b>Pricing</b></h3>
<p><b>Initial Consultation:</b>&nbsp;90-120 minutes for $150&nbsp;</p>
<p><b>Follow-Up Meetings:</b>&nbsp;30 minutes for $40; 60 minutes for $60&nbsp;</p>
<p><b>Packages:</b>&nbsp;4 sessions/month (once weekly) for $200 ($40 total discount) = $50/60 minute session</p>
<p><b>Cancellations:</b> &lt;24 hours or missed appointments:&nbsp;$20</p>
  </div>
</template>

<style>
.bookNow {
    color: white;
    background: #2c3e50;
    cursor: pointer;
}
</style>